import { memo, useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../../components/Button";
import { FileUploader } from "react-drag-drop-files";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ReactComponent as MoveIcon } from "../../../assest/svg/MoveIcon.svg";
import { formatFileSize } from "../../../utils/fileFormatUtil";

const PeopleBodyDetailModal = ({
  open,
  handleClose,
  handleSave,
  imageList,
}) => {
  const [childList, setChildList] = useState([]);

  useEffect(() => {
    setChildList(imageList);
  }, [imageList]);

  const handleFileChange = async (files) => {
    const fileReaders = [];

    Object.keys(files).map((key, index) => {
      const reader = new FileReader();
      const fileObject = files[key];
      reader.onload = () => {
        setChildList((prevImages) => [
          ...prevImages,
          {
            fileOriginNm: fileObject.name,
            fileSize: fileObject.size,
            file: fileObject,
            filePath: reader.result,
            order: childList.length + (index + 1),
          },
        ]);
      };
      reader.readAsDataURL(fileObject);
      fileReaders.push(reader);
    });
  };

  const handleRemove = (order) => {
    let childLIst = [...childList];
    let resultList = childLIst
      .filter((item) => item.order !== order)
      .map((upate, idx) => ({ ...upate, order: idx + 1 }));

    setChildList(resultList);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const updatedItems = Array.from(childList);
    const [movedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, movedItem);

    //새로운 order 값으로 업데이트
    const reorderedItems = updatedItems.map((item, index) => ({
      ...item,
      order: index + 1,
    }));

    setChildList(reorderedItems);
  };

  return (
    <Dialog open={open} maxWidth="lg" fullWidth={true}>
      <DialogTitle>상세 이미지 설정</DialogTitle>

      <DialogContent dividers>
        <FileUploader
          multiple={true}
          handleChange={handleFileChange}
          name="file"
          types={["jpg", "jpeg", "png"]}
          label="여기로 이미지를 드래그하거나 파일을 업로드 하세요."
        >
          <FileInputArea>
            여기로 이미지를 드래그하거나 파일을 업로드 하세요.
          </FileInputArea>
        </FileUploader>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {childList.length > 0 &&
                  childList
                    ?.sort((a, b) => a.order - b.order)
                    .map((item, index) => {
                      return (
                        <Draggable
                          key={item.order}
                          draggableId={String(item.order)}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Preview
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <Index>
                                <div className="tag">No.{item.order}</div>
                              </Index>
                              <ImageArea>
                                <div className="img">
                                  <img src={item.filePath} alt="images" />
                                  <div
                                    id="cancel"
                                    onClick={() => handleRemove(item.order)}
                                  >
                                    X
                                  </div>
                                </div>
                                <div className="info">
                                  <p>{item.fileOriginNm}</p>
                                  <p>{formatFileSize(item.fileSize)}</p>
                                </div>
                              </ImageArea>
                              <MoveArea>
                                <MoveIcon />
                              </MoveArea>
                            </Preview>
                          )}
                        </Draggable>
                      );
                    })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </DialogContent>

      <DialogActions>
        <Button text={"취소"} variant="contained" onClick={handleClose} />
        <Button
          type="primary"
          autoFocus
          variant="contained"
          text={"저장"}
          isDisable={childList?.length <= 0}
          onClick={() => handleSave(childList)}
        />
      </DialogActions>
    </Dialog>
  );
};

const ImageArea = styled.div`
  display: flex;
  flex: 1;
  gap: 20px;
`;

const MoveArea = styled.div`
  width: 40px;
  flex-basis: 40px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 40px;
    background: #f9fafc;
  }

  display: flex;
  align-items: center;
  justify-content: center;
`;
const Index = styled.div`
  flex-basis: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  .tag {
    width: 100%;
    background: #eef3ff;
    color: #2d5ed6;
    border-radius: 8px;
    padding: 12px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Preview = styled.div`
  padding: 1rem;
  position: relative;
  display: flex;
  border: 1px solid #e3e6eb;
  background: white;
  border-radius: 4px;

  display: flex;
  justify-content: space-between;
  gap: 20px;

  .img {
    width: 180px;
    position: relative;
    aspect-ratio: 1/1;
    img {
      width: 100%;
    }

    #cancel {
      position: absolute;
      top: 0;
      right: 0;
      padding: 7px;
      aspect-ratio: 1/1;
      background-color: #000000cc;
      color: white;
      cursor: pointer;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-top: 6px;

    .size {
      font-size: 1rem;
      color: #999999;
    }
  }
`;

const FileInputArea = styled.div`
  background-color: #f9fafc;
  border: 1px solid #cccccc;
  border-style: dashed;
  padding-top: 37px;
  padding-bottom: 37px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #999999;
`;

export default memo(PeopleBodyDetailModal);
