import { Checkbox } from "@mui/material";
import React from "react";
import styled from "styled-components";

const StyledCheckbox = (props) => {
  return (
    <Container>
      <CustomCheckbox
        {...props}
        sx={{
          color: "#45484C",
          "&.Mui-checked": {
            color: "#45484C",
          },
          "&:hover": {
            backgroundColor: "transparant !important",
          },
        }}
        color="default"
      />
    </Container>
  );
};

const Container = styled.div`
  &:hover {
    background-color: transparant !important;
  }

  .MuiCheckbox-root > span::before {
    background: none !important;
    transform: none !important;
  }
`;

const CustomCheckbox = styled(Checkbox)`
  border: none !important;
  background: transparant !important;

  &:hover {
    background: none !important;
  }
  &:active {
    background: none !important;
  }
  stroke: "#ffffff";
  stroke-width: 1;
`;

export default React.memo(StyledCheckbox);
