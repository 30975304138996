import api from "./baseApi";

export async function insertScrolling(payload) {
  const result = await api.post(`/about/images`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    transformRequest: [
      function () {
        return payload;
      },
    ],
  });
  return result?.data;
}

export async function getScrollingList() {
  const result = await api.get(`/about/images`);
  return result?.data;
}

export async function deleteAboutItems(payload) {
  const result = await api.delete(`/about/list/`, {
    data: payload,
  });
  return result?.data;
}

export async function getDetail(payload) {
  const result = await api.get(`/about/detail/${payload.id}`);
  return result?.data;
}

export async function changeOrder(payload) {
  const result = await api.post(`/about/order`, {
    items: payload,
  });
  return result?.data;
}

export async function updateItem(payload) {
  const result = await api.put(`/about/images`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    transformRequest: [
      function () {
        return payload;
      },
    ],
  });
  return result?.data;
}
