import { memo, useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../../components/Button";
import { getScrollingList } from "../../../api/api";
import StyledTable from "../../../components/Table";
import { useLocation, useNavigate } from "react-router";
import { deleteAboutItems } from "../../../api/aboutApi";
import { ReactComponent as SearchIcon } from "../../../assest/svg/SearchIcon.svg";
import { FormControl, MenuItem, Select } from "@mui/material";
const ContentsManageListPage = () => {
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const [checkList, setCheckList] = useState([]);
  const [originData, setOriginData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const { pathname } = useLocation();
  const [status, setStatus] = useState("DELETE_OK");
  useEffect(() => {
    console.log("실행");
    getScrollingList().then((res) => {
      setList(res.data);
      setOriginData(res.data);
    });
  }, [pathname, status]);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const thead = {
    projectNm: "프로젝트명",
    image: "대표 이미지",
  };

  const handleSelectRow = (item) => {
    navigate("/cms/page/about/detail/" + item.id);
  };

  const handleDelete = () => {
    deleteAboutItems(checkList)
      .then((res) => {})
      .finally((param) => {
        setStatus("DELETE_OK");
      });
  };

  const handleSearch = () => {
    setList((list) =>
      originData.filter((item) => item.projectNm.includes(searchValue))
    );
  };

  return (
    <Container>
      <Title>스크롤링 콘텐츠</Title>

      <Section>
        <Header>
          <SectionTitle>
            리스트({list?.length})
            <SearchField>
              <StyledInput
                placeholder="검색할 프로젝트명을 입력해주세요."
                onChange={(e) => setSearchValue(e.target.value)}
              ></StyledInput>
              <SearchIcon onClick={handleSearch} />
            </SearchField>
          </SectionTitle>

          <ButtonGroup>
            <Button type="secondary" text="삭제" onClick={handleDelete}>
              삭제
            </Button>
            <Button
              type="primary"
              text="등록"
              onClick={() => navigate("/cms/page/about")}
            ></Button>
          </ButtonGroup>
        </Header>
        <StyledTable
          data={[...list]}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          thead={thead}
          handleSelectRow={handleSelectRow}
          handleCheckedList={setCheckList}
          setStatus={setStatus}
        />

        <Item></Item>
      </Section>
    </Container>
  );
};

export const SearchField = styled.div`
  display: flex;
  align-items: center;
  gap: 0.3rem;

  position: relative;

  input {
    padding-right: 40px;
  }

  svg {
    position: absolute;
    right: 20px;
    cursor: pointer;
  }
`;

export const StyledInput = styled.input`
  gap: 0px;
  width: 280px;
  border-radius: 8px;
  border: 1px solid #c8cace;
  opacity: 0px;
  outline: none;
  padding: 10px 11px;
`;

const Item = styled.div`
  .order {
  }
`;

const FileInputArea = styled.div`
  background-color: #f9fafc;
  border: 1px solid #cccccc;
  border-style: dashed;
  padding-top: 37px;
  padding-bottom: 37px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #999999;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
`;
const SectionTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  display: flex;
  gap: 1rem;
  align-items: center;
`;
const ButtonGroup = styled.div`
  display: flex;
  gap: 7px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
`;

const Section = styled.div`
  background-color: #ffffff;
  border-radius: 20px;
  padding: 24px;
`;
export default memo(ContentsManageListPage);
