import api from "./baseApi";

export async function login(params) {
  const result = await api.post(`/auth/login`, params);
  let { data } = result;
  return data?.data?.accessToken;
}

///

export async function insertScrolling(payload) {
  const result = await api.post(`/about/images`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    transformRequest: [
      function () {
        return payload;
      },
    ],
  });
  return result.data;
}

export async function getScrollingList() {
  const result = await api.get(`/about/images`);
  let { data } = result;
  return data;
}

export async function getDeleteList() {
  const result = await api.post(`/about/images`);
  let { data } = result;
  return data;
}

export async function getDetail() {}

////

///////////////////////////////////////////////////

export async function insertPeopleBody(payload) {
  const result = await api.post(`/contents/images`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    transformRequest: [
      function () {
        return payload;
      },
    ],
  });
  return result?.data;
}

export async function getPeopleBodyList() {
  const result = await api.get(`/contents/images`);
  let { data } = result;
  return data;
}

export async function getPeopleBodyDetail(payload) {
  const result = await api.get(`/contents/detail/` + payload.id);
  let { data } = result;
  return data;
}
