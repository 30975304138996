// src/App.js
import React, { useCallback, useEffect, useRef, useState } from "react";
import Pagination from "./Pagination";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ReactComponent as MoveIcon } from "../assest/svg/MoveIcon.svg";
import VideoIcon from "../assest/images/VideoIcon.png";

import StyledCheckbox from "./StyledCheckbox";
import { useLocation } from "react-router";
import { changeOrder as changeAboutOrder } from "../api/aboutApi";
import { changeOrder as changeMainOrder } from "../api/mainApi";
import { changeOrder as changeContentsOrder } from "../api/contentsApi";
import { MenuItem, Select } from "@mui/material";

const StyledTable = ({
  thead,
  data,
  handleSelectRow,
  handleCheckedList,
  setStatus,
}) => {
  const [tbody, setTbody] = useState(data);
  const { pathname } = useLocation();
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [checkList, setCheckList] = useState([]);
  const [resultList, setResultList] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const firstInit = useRef(false);

  useEffect(() => {
    setTbody([...data]);
  }, [data]);

  useEffect(() => {
    setCurrentPage(0);
  }, [itemsPerPage, setCurrentPage]);

  useEffect(() => {
    setStartIndex(currentPage * itemsPerPage);
  }, [currentPage, itemsPerPage, setCurrentPage]);

  useEffect(() => {
    const selectedData = tbody?.slice(startIndex, startIndex + itemsPerPage);
    setResultList(selectedData);
  }, [itemsPerPage, startIndex, tbody, currentPage]);

  const handleCheckBox = useCallback(
    (e) => {
      let { name, checked } = e.target;

      if (name === "isCheckAll") {
        if (checked) {
          setCheckList(resultList.map((item) => item.id));
        } else {
          setCheckList([]);
        }
      } else {
        if (checked) {
          setCheckList([...checkList, name]);
        } else {
          setCheckList(checkList.filter((item) => item !== name));
        }
      }
    },
    [resultList, checkList]
  );

  // 드래그 앤 드랍 결과 처리 함수
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const updatedItems = Array.from(tbody).sort((a, b) => a?.order - b?.order);
    const [movedItem] = updatedItems.splice(result.source.index, 1);
    console.log("source", result.source.index);
    console.log("updatedItems", updatedItems, "movedItem", movedItem);

    updatedItems.splice(result.destination.index, 0, movedItem);

    //새로운 order 값으로 업데이트
    const reorderedItems = updatedItems.map((item, index) => ({
      ...item,
      order: index,
    }));

    setTbody(reorderedItems);
    handleOrder(reorderedItems);
  };

  const handleOrder = (orderItem) => {
    if (pathname.includes("/about")) {
      changeAboutOrder(orderItem).then((res) => setStatus("ORDER_OK"));
    } else if (pathname.includes("/main")) {
      changeMainOrder(orderItem).then((res) => setStatus("ORDER_OK"));
    } else if (pathname.includes("/human")) {
      changeContentsOrder(orderItem).then((res) => setStatus("ORDER_OK"));
    }
  };

  useEffect(() => {
    if (handleCheckedList !== null) handleCheckedList(checkList);
  }, [checkList, handleCheckedList]);

  return (
    <Container>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droappable">
          {(provided) => (
            <Table ref={provided.innerRef} {...provided.droppableProps}>
              <Thead>
                <tr>
                  <th>
                    <StyledCheckbox
                      name="isCheckAll"
                      onChange={handleCheckBox}
                    />
                  </th>
                  <th>No</th>
                  {Object.keys(thead)?.map((item) => {
                    return <th>{thead[item]}</th>;
                  })}
                  <th>순서 변경</th>
                </tr>
              </Thead>
              <tbody>
                {resultList
                  .sort((a, b) => a?.order - b?.order)
                  .map((row, index) => {
                    return (
                      <Draggable
                        key={row.id}
                        draggableId={String(row.id)}
                        index={startIndex + index}
                      >
                        {(provided, snapshot) => (
                          <tr
                            key={index}
                            onClick={() => handleSelectRow(row)}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <>
                              <td
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <StyledCheckbox
                                  name={row.id}
                                  onChange={handleCheckBox}
                                  checked={checkList.some(
                                    (check) => Number(check) === Number(row.id)
                                  )}
                                />
                              </td>
                              <td>{startIndex + index + 1}</td>

                              {Object.keys(thead).map((key) => {
                                return (
                                  <>
                                    {key === "image" ? (
                                      <td>
                                        {row?.fileFormat === "video" ? (
                                          <img
                                            key={row.id}
                                            src={VideoIcon}
                                            alt={`img-${row.id}`}
                                          />
                                        ) : (
                                          <img
                                            key={row.id}
                                            src={row?.filePath}
                                            alt={`img-${row.id}`}
                                          />
                                        )}
                                      </td>
                                    ) : (
                                      <>
                                        {key === "images" ? (
                                          <td>
                                            <div className="img_tableList">
                                              {row?.children
                                                ?.sort(
                                                  (a, b) => a?.order - b?.order
                                                )
                                                .map((item) => {
                                                  return (
                                                    <div>
                                                      <img
                                                        key={item.id}
                                                        src={item?.filePath}
                                                        alt={`img-${item.id}`}
                                                      />
                                                    </div>
                                                  );
                                                })}
                                            </div>
                                          </td>
                                        ) : (
                                          <>
                                            <td>{row?.[key]}</td>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                );
                              })}
                              <td>
                                <div
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div id="moveIcon">
                                    <MoveIcon />
                                  </div>
                                </div>
                              </td>
                            </>
                          </tr>
                        )}
                      </Draggable>
                    );
                  })}
                {provided.placeholder && (
                  <tr>
                    <td colSpan="3" style={{ padding: 0, border: 0 }}>
                      <div style={{ display: "none" }}>test</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </Droppable>
      </DragDropContext>

      <PaginationContainer>
        <Pagination
          totalItems={data.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setItemPerPage={setItemPerPage}
        />
      </PaginationContainer>
    </Container>
  );
};

const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Table = styled.table`
  width: 100%;
  border: 1px solid #e3e6eb;

  border-collapse: collapse;
  outline: none;

  tr td {
    padding: 0.4rem;
  }

  tbody {
    font-weight: 400 !important;

    tr {
      &:hover {
        background-color: #f3f6f9;
      }
      border-top: 1px solid #e3e6eb;
      text-align: center;
      cursor: pointer;
    }

    td {
      align-items: center;
      justify-content: center;
      vertical-align: middle;

      #moveIcon {
        text-align: center;
        width: 100%;
        width: 16px;
        height: 20px;
        gap: 0px;
        border-radius: 2px 0px 0px 0px;
        opacity: 0px;
        background: #e3e6eb;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    #video {
      width: 2rem;
      aspect-ratio: 1/1;
    }

    img {
      width: 2.8rem;
      aspect-ratio: 1 / 0.8;
    }

    .img_tableList {
      display: flex;

      img {
        width: 2.8rem;
        aspect-ratio: 1 / 0.8;
        object-fit: cover;
      }
    }
  }
`;

const Thead = styled.thead`
  tr,
  th {
    padding: 0.4rem;
    background: #edf0f4;
    font-weight: 500 !important;
  }
`;

const Container = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

export default StyledTable;
