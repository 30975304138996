import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import Image from "../../components/Image";

const HorizontalScrollGallery = ({ speed = 45000, imagesList }) => {
  const images = imagesList.map((image, index) => ({
    id: index,
    filePath: image.filePath,
  }));

 
  return (
    <Inner>
      <Wrapper>
        <Section style={{ "--speed": `${speed}ms` }}>
          {images
            .sort((a, b) => a.order - b.order)
            .map(({ id, filePath }) => (
              <div className="image" key={id}>
                <Img src={filePath} alt={id} />
              </div>
            ))}
        </Section>
        <Section style={{ "--speed": `${speed}ms` }}>
          {images
            .sort((a, b) => a.order - b.order)
            .map(({ id, filePath }) => (
              <div className="image" key={id}>
                <Img src={filePath} alt={id} />
              </div>
            ))}
        </Section>
        <Section style={{ "--speed": `${speed}ms` }}>
          {images
            .sort((a, b) => a.order - b.order)
            .map(({ id, filePath }) => (
              <div className="image" key={id}>
                <Img src={filePath} alt={id} />
              </div>
            ))}
        </Section>
      </Wrapper>
    </Inner>
  );
};

const swipe = keyframes`
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(-100%);
  }
`;

const Img = styled.img`
  width: 100%;
  padding: 0 0px;
  height: 100%;
  object-fit: none;

  &:last-of-type {
    padding-left: 0;
  }
`;

const Inner = styled.div`
  position: relative;
  width: 100%;
  height: 5rem;
`;

const Wrapper = styled.div`
  display: flex;
`;

const Section = styled.section`
  display: flex;
  animation: ${swipe} var(--speed) linear infinite backwards;
  .image {
    width: 400px;
    padding-left: 0.8rem;

    aspect-ratio: 1/1;
  }
`;

export default HorizontalScrollGallery;
