import { memo, useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../../components/Button";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router";
import { insertMainContents } from "../../../api/mainApi";
import {
  FormContainer,
  FormItem,
  Point,
} from "../contents/PeopleBodyModifyPage";
import { TextField } from "@mui/material";
import { formatFileSize, formatType } from "../../../utils/fileFormatUtil";
import { Title } from "./MainContentsListPage";

const MainContentsManagePage = () => {
  const [file, setFile] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const [form, setForm] = useState({
    projectNm: "",
    fileFormat: "",
  });
  const handleFileChange = (file) => {
    setFile(file);

    let fileFormat = formatType(file);
    setForm({ ...form, fileFormat: fileFormat });

    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve) => {
      reader.onload = () => {
        setImgSrc(reader.result);
        resolve();
      };
    });
  };

  const handleSave = () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("json", JSON.stringify(form));
    insertMainContents(formData).then((res) => {
      if (res?.message === "ok") {
        setStatus("INSERT_OK");
      }
    });
  };

  useEffect(() => {
    if (status === "INSERT_OK") {
      navigate("/cms/page/main/list");
    }
  }, [status, navigate]);

  const handleControlForm = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const [isButtonDisable, setIsButtonDisable] = useState(true);

  useEffect(() => {
    if (file !== null && form.projectNm.length > 0) {
      setIsButtonDisable(false);
    } else {
      setIsButtonDisable(true);
    }
  }, [form, file]);

  return (
    <Container>
      <Title>메인 콘텐츠</Title>

      <Section>
        <Header>
          <SectionTitle>이미지 등록</SectionTitle>
          <ButtonGroup>
            <Button text="취소" onClick={() => navigate("/cms/page/main/list")}>
              취소
            </Button>
            <Button
              type={"primary"}
              isDisable={isButtonDisable}
              text="저장"
              onClick={handleSave}
            >
              저장
            </Button>
          </ButtonGroup>
        </Header>

        <FormContainer>
          <div className="formChild">
            <FormItem>
              프로젝트명 <Point>*</Point>
            </FormItem>
            <TextField
              name="projectNm"
              onChange={handleControlForm}
              value={form.projectNm}
              variant="outlined"
              placeholder="프로젝트명을 입력하세요."
              fullWidth={true}
            />
          </div>
        </FormContainer>

        <FormItem>
          이미지 등록 <Point>*</Point>
        </FormItem>
        <FileUploader
          multiple={false}
          handleChange={handleFileChange}
          name="file"
          types={["jpg", "jpeg", "png", "mp4"]}
          label="여기로 이미지를 드래그하거나 파일을 업로드 하세요."
        >
          <FileInputArea>
            여기로 이미지를 드래그하거나 파일을 업로드 하세요.
          </FileInputArea>
        </FileUploader>

        {imgSrc !== null && (
          <Preview>
            {form?.fileFormat === "video" ? (
              <>
                <div className="img">
                  <video controls src={imgSrc} alt="images" />
                  <div id="cancel" onClick={() => setImgSrc(null)}>
                    X
                  </div>
                </div>
                <div className="info">
                  <p>{file?.name}</p>
                  <p>{formatFileSize(file?.size)}</p>
                </div>
              </>
            ) : (
              <>
                <div className="img">
                  <img src={imgSrc} alt="images" />
                  <div id="cancel" onClick={() => setImgSrc(null)}>
                    X
                  </div>
                </div>
                <div className="info">
                  <p>{file?.name}</p>
                  <p>{formatFileSize(file?.size)}</p>
                </div>
              </>
            )}
          </Preview>
        )}
      </Section>
    </Container>
  );
};

const Preview = styled.div`
  width: 100%;
  padding: 1rem;
  position: relative;
  display: flex;
  border: 1px solid #e3e6eb;
  border-radius: 4px;

  display: flex;
  gap: 20px;

  .img {
    width: 180px;
    position: relative;
    aspect-ratio: 1/1;
    img {
      width: 100%;
    }
    video {
      width: 100%;
    }

    #cancel {
      position: absolute;
      top: 0;
      right: 0;
      padding: 7px;
      aspect-ratio: 1/1;
      background-color: #000000cc;
      color: white;
      cursor: pointer;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-top: 6px;

    .size {
      font-size: 1rem;
      color: #999999;
    }
  }
`;

const Item = styled.div`
  .order {
  }
`;

const FileInputArea = styled.div`
  background-color: #f9fafc;
  border: 1px solid #cccccc;
  border-style: dashed;
  padding-top: 37px;
  padding-bottom: 37px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #999999;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  border-bottom: 1px solid #dedede;
`;
const SectionTitle = styled.div``;
const ButtonGroup = styled.div`
  display: flex;
  gap: 7px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Section = styled.div`
  background-color: #ffffff;
  border-radius: 20px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export default memo(MainContentsManagePage);
