import { memo } from "react";
import styled from "styled-components";
import BgImage from "../assest/images/NmaeTag.png";

const NameTag = () => {
  const info = [
    { title: "CEO", value: "Sangin Lee" },
    { title: "Business Licence", value: "127-87-02231" },
  ];

  return (
    <Container>
      <FormGroup>
        {info.map((item) => {
          return (
            <FormItem>
              <div className="name">{item.title}</div>
              <div className="value">{item.value}</div>
            </FormItem>
          );
        })}
      </FormGroup>
      <img src={BgImage} alt="nameTAG" />
    </Container>
  );
};

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: absolute;
  bottom: 0;
  padding: 30px;
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  bottom: 0px;
  z-index: 1;
  .name {
    font-size: 1rem;
    font-family: 600;
  }
  .value {
    font-size: 30px;
    font-family: 600;
  }
`;

const Container = styled.div`
  color: white;
  width: 330px;
  position: relative;

  &:hover {
    filter: drop-shadow(20px 30px 80px rgba(109, 109, 109, 0.21));
    transition: 0.8s ease all;
  }

  /* background-image: url(${BgImage});
  background-repeat: no-repeat; */
  img {
    width: 100%;
    object-fit: cover;
  }
`;

export default memo(NameTag);
