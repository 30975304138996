import { memo, useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../../components/Button";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate, useParams } from "react-router";
import { getDetail, updateItem } from "../../../api/aboutApi";
import {
  FormContainer,
  FormItem,
  Point,
} from "../contents/PeopleBodyModifyPage";
import { TextField } from "@mui/material";
import { formatFileSize } from "../../../utils/fileFormatUtil";

const ContentsManageModifyPage = () => {
  const [file, setFile] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const navigate = useNavigate();
  const [detail, setDetail] = useState({
    fileNm: "",
    projectNm: "",
    fileOriginNm: "",
    filePath: null,
    fileSize: 0,
    id: 0,
    order: 0,
  });
  const { id } = useParams();

  useEffect(() => {
    getDetail({ id }).then((res) => {
      setDetail(res?.data[0]);
    });
  }, [id]);

  const handleFileChange = (file) => {
    setFile(file);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve) => {
      reader.onload = () => {
        setImgSrc(reader.result);
        setDetail({
          ...detail,
          fileSize: file.size,
          fileOriginNm: file.name,
        });
        resolve();
      };
    });
  };

  const handleSave = () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("json", JSON.stringify(detail));

    updateItem(formData).then((res) => {
      if (res?.message === "ok") {
        navigate("/cms/page/about/list");
      }
    });
  };

  const handleControlForm = (e) => {
    const { name, value } = e.target;
    setDetail({ ...detail, [name]: value });
  };

  const [isButtonDisable, setIsButtonDisable] = useState(true);

  useEffect(() => {
    if (detail.fileSize > 0 && detail?.projectNm?.length > 0) {
      setIsButtonDisable(false);
    } else {
      setIsButtonDisable(true);
    }
  }, [detail, file]);

  return (
    <Container>
      <Title>스크롤링 콘텐츠</Title>

      <Section>
        <Header>
          <SectionTitle>이미지 등록</SectionTitle>
          <ButtonGroup>
            <Button
              text="취소"
              type={"secondary"}
              onClick={() => navigate("/cms/page/about/list")}
            >
              취소
            </Button>
            <Button
              type={"primary"}
              isDisable={isButtonDisable}
              text="저장"
              onClick={handleSave}
            >
              저장
            </Button>
          </ButtonGroup>
        </Header>

        <FormContainer>
          <div className="formChild">
            <FormItem>
              프로젝트명 <Point>*</Point>
            </FormItem>
            <TextField
              name="projectNm"
              onChange={handleControlForm}
              value={detail.projectNm}
              variant="outlined"
              placeholder="프로젝트명을 입력하세요."
              fullWidth={true}
            />
          </div>
        </FormContainer>

        <FileUploader
          multiple={false}
          handleChange={handleFileChange}
          name="file"
          types={["jpg", "jpeg", "png"]}
          label="여기로 이미지를 드래그하거나 파일을 업로드 하세요."
        >
          <FileInputArea>
            여기로 이미지를 드래그하거나 파일을 업로드 하세요.
          </FileInputArea>
        </FileUploader>

        {detail?.fileSize > 0 && (
          <Preview>
            <div className="img">
              <img
                src={imgSrc !== null ? imgSrc : detail?.filePath}
                alt="images"
              />
              <div
                id="cancel"
                onClick={() => {
                  setImgSrc(null);
                  setDetail({
                    ...detail,
                    filePath: null,
                    fileOriginNm: "",
                    fileNm: "",
                    fileSize: "",
                  });
                }}
              >
                X
              </div>
            </div>
            <div className="info">
              <p>{detail?.fileOriginNm}</p>
              <p>{formatFileSize(detail?.fileSize)}</p>
            </div>
          </Preview>
        )}
      </Section>
    </Container>
  );
};

const Preview = styled.div`
  width: 100%;
  padding: 1rem;
  position: relative;
  display: flex;
  border: 1px solid #e3e6eb;
  border-radius: 4px;

  display: flex;
  gap: 20px;

  .img {
    width: 180px;
    position: relative;
    aspect-ratio: 1/1;
    img {
      width: 100%;
    }

    #cancel {
      position: absolute;
      top: 0;
      right: 0;
      padding: 7px;
      aspect-ratio: 1/1;
      background-color: #000000cc;
      color: white;
      cursor: pointer;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding-top: 6px;

    .size {
      font-size: 1rem;
      color: #999999;
    }
  }
`;

const FileInputArea = styled.div`
  background-color: #f9fafc;
  border: 1px solid #cccccc;
  border-style: dashed;
  padding-top: 37px;
  padding-bottom: 37px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #999999;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
`;
const SectionTitle = styled.div``;
const ButtonGroup = styled.div`
  display: flex;
  gap: 7px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
`;

const Section = styled.div`
  background-color: #ffffff;
  border-radius: 20px;
  padding: 24px;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export default memo(ContentsManageModifyPage);
