import { memo, useState } from "react";
import styled, { css } from "styled-components";
import Image from "./Image";
const Card = ({ src, onClick, className }) => {
  return (
    <Container onClick={onClick} className={className}>
      <Image src={src} alt="test" loading="lazy" />
    </Container>
  );
};

const Cover = styled.div`
  text-align: center;
`;

const HoverInfo = styled.div`
  position: absolute;
  display: none;
  z-index: 1;
  opacity: 0.6;
  background-color: black;
  color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  ${({ isHover }) =>
    isHover &&
    css`
      display: block;
      transition: 0.4s ease all;
    `}
`;

const Container = styled.div`
  position: relative;
  overflow: hidden;
  transition: 0.3s ease;
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 100%;
  &:hover {
    transform: scale(0.9);
    filter: brightness(50%);
    cursor: pointer;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default memo(Card);
