import { memo, useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../../components/Button";
import StyledTable from "../../../components/Table";
import { useLocation, useNavigate } from "react-router";
import { deleteMainItems, getMainContentsList } from "../../../api/mainApi";
import { SearchField, StyledInput } from "../about/ContentsManageListPage";
import { ReactComponent as SearchIcon } from "../../../assest/svg/SearchIcon.svg";
import { FormControl, MenuItem, Select } from "@mui/material";
const MainContentsListPage = () => {
  const [list, setList] = useState([]);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [originData, setOriginData] = useState([]);
  const [checkList, setCheckList] = useState([]);
  const { pathname } = useLocation();
  const [status, setStatus] = useState("");

  useEffect(() => {
    getMainContentsList().then((res) => {
      if (res.message === "ok") {
        console.log("res", res);
        setList(res.data);
        setOriginData(res.data);
      }
    });
  }, [pathname, status]);

  const thead = {
    projectNm: "프로젝트명",
    image: "대표 이미지",
    fileFormat: "파일 유형",
  };

  const handleSelectRow = (item) => {
    setList([]);
    navigate("/cms/page/main/detail/" + item.id);
  };

  const handleDelete = () => {
    deleteMainItems(checkList)
      .then((res) => {})
      .finally((res) => {
        setStatus("DELETE_OK");
      });
  };

  const handleSearch = () => {
    setList((list) =>
      originData
        .filter((item) => item.projectNm.includes(searchValue))
        .filter((item) =>
          searchCondition === "total"
            ? true
            : item?.fileFormat === searchCondition
        )
    );
  };

  const [searchCondition, setSearchCondition] = useState("total");

  const handleSearchCondition = (e) => {
    const { value } = e.target;
    setSearchCondition(value);
  };

  return (
    <Container>
      <Title>메인 콘텐츠</Title>

      <Section>
        <Header>
          <SectionTitle>
            리스트({list?.length})
            <FormControl sx={{ width: 140, margin: 0 }} size="small">
              <Select
                name="type"
                value={searchCondition}
                onChange={handleSearchCondition}
              >
                <MenuItem value={"total"}>전체</MenuItem>
                <MenuItem value={"image"}>이미지</MenuItem>
                <MenuItem value={"video"}>비디오</MenuItem>
              </Select>
            </FormControl>
            <SearchField>
              <StyledInput
                placeholder="검색할 프로젝트명을 입력해주세요."
                onChange={(e) => setSearchValue(e.target.value)}
              ></StyledInput>
              <SearchIcon onClick={handleSearch} />
            </SearchField>
          </SectionTitle>

          <ButtonGroup>
            <Button type="secondary" text="삭제" onClick={handleDelete}>
              삭제
            </Button>
            <Button
              type="primary"
              text="등록"
              onClick={() => navigate("/cms/page/main")}
            ></Button>
          </ButtonGroup>
        </Header>
        <StyledTable
          data={list}
          thead={thead}
          handleSelectRow={handleSelectRow}
          handleCheckedList={setCheckList}
          setStatus={setStatus}
        />

        <Item></Item>
      </Section>
    </Container>
  );
};

const Item = styled.div`
  .order {
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
`;
const SectionTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  display: flex;
  gap: 1rem;
  align-items: center;

  padding: 6px 11px;
`;
const ButtonGroup = styled.div`
  display: flex;
  gap: 7px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
export const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
`;

const Section = styled.div`
  background-color: #ffffff;
  border-radius: 20px;
  padding: 24px;
`;
export default memo(MainContentsListPage);
