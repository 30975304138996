export function formatFileSize(bytes) {
  const units = ["B", "KB", "MB", "GB", "TB"];

  let size = bytes;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  return `${size?.toFixed(2)} ${units[unitIndex]}`;
}

export function formatType(file) {
  const mimeType = file.type;

  if (mimeType.startsWith("image/")) {
    return "image";
  } else if (mimeType.startsWith("video/")) {
    return "video";
  }
}
