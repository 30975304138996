import React, { useState } from "react";
import styled from "styled-components";

import LoadingIcon from "../assest/loading/cmsLoading.json";
import Lottie from "lottie-react";
function Image({ src, alt,onClick }) {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  return (
    <>
      <img src={src} alt={alt} onLoad={handleImageLoad} loading="lazy" onClick={onClick} />
      {isImageLoaded ? (
        <></>
      ) : (
        <Loading>
          <Lottie animationData={LoadingIcon} loop={true} />
        </Loading>
      )}
    </>
  );
}

const Loading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0000009c;
  z-index: 1;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Image;
