import { Children, memo } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as WhiteLogo } from "../../assest/svg/logo.svg";
import Button from "../../components/Button";
import { login } from "../../api/api";
import { Outlet, useLocation, useNavigate } from "react-router";
const CMSLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    navigate("/cms");
  };
  const menuList = [
    {
      name: "Main",
      children: [{ name: "메인 콘텐츠", path: "/cms/page/main/list" }],
    },
    {
      name: "About",
      children: [{ name: "스크롤링 콘텐츠", path: "/cms/page/about/list" }],
    },
    {
      name: "Contents",
      children: [
        { name: "인물/바디 프로필 콘텐츠", path: "/cms/page/human/list" },
      ],
    },
  ];

  return (
    <Container>
      <SideBar>
        <LogoBox>
          <WhiteLogo />
        </LogoBox>

        <MenuContainer>
          {menuList.map((item) => {
            return (
              <ItemGroup>
                <div className="parents">{item.name}</div>
                {item.children.map((child) => {
                  return (
                    <Child
                      onClick={() => navigate(child.path)}
                      isActive={pathname.indexOf(child.path) >= 0}
                    >
                      {child.name}
                    </Child>
                  );
                })}
              </ItemGroup>
            );
          })}
        </MenuContainer>

        <Logout onClick={handleLogout}>로그아웃</Logout>
      </SideBar>

      <SubOutlet>
        <Outlet />
      </SubOutlet>
    </Container>
  );
};

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
`;

const SubOutlet = styled.div`
  width: calc(100% - 260px);
  /* padding: 60px; */
  position: absolute;
  right: 0;
  top: 0;
  background-color: #edf0f4 !important;
  color: black;

  height: 100%;
  overflow: auto;

  & > div {
    padding: 60px;
  }
`;

const LogoBox = styled.div`
  padding-top: 40px;
  padding-left: 24px;
`;

const Logout = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  background: #111111;
  border-radius: 100px;
  padding: 17px 81px;
  margin: 14px;
  left: 1px;
  bottom: 18px;
  cursor: pointer;
`;

const ItemGroup = styled.div`
  .parents {
    padding: 17px 30px 17px 30px;
    font-weight: 500 !important;
  }
`;

const Child = styled.div`
  background-color: #111111;
  padding: 17px 30px 17px 30px;
  /* display: none; */
  cursor: pointer;
  font-weight: 500 !important;

  ${({ isActive }) =>
    isActive &&
    css`
      color: #84a7ff;
      font-weight: 600 !important;
    `}
`;

const SideBar = styled.div`
  position: relative;
  width: 260px;
  height: 100%;
  left: 0;
  background: #222222;

  position: absolute;
  bottom: 0;
`;

const Container = styled.div`
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  color: white;
  background-color: #edf0f4 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  overflow: hidden;
`;

export default memo(CMSLayout);
