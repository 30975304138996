import styled, { css } from "styled-components";
import Image from "./Image";
import { useMediaQuery } from "react-responsive";

const ImagePreview = ({ filePath, handleClose }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <DarkBackground isMobile={isMobile} onClick={() => handleClose()}>
      <Image src={filePath} />
    </DarkBackground>
  );
};

const DarkBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #00000085;
  z-index: 9999;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 62vh;
  }

  ${({ isMobile }) =>
    isMobile &&
    css`
      img {
        width: 47vh;
      }
    `}
`;

export default ImagePreview;
