import styled, { css } from "styled-components";

import { ReactComponent as SmallLeft } from "../assest/svg/SmallLeft.svg";
import { ReactComponent as BigLeft } from "../assest/svg/BigLeft.svg";
import { ReactComponent as SmallRight } from "../assest/svg/SmallRight.svg";
import { ReactComponent as BigRight } from "../assest/svg/BigRight.svg";
import { ReactComponent as DownIcon } from "../assest/svg/SelectDown.svg";

const Pagination = ({
  totalItems,
  itemsPerPage,
  currentPage,
  setCurrentPage,
  setItemPerPage,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <>
      <Container className="pagination">
        <div className="selectBox">
          <select size="small" onChange={(e) => setItemPerPage(e.target.value)}>
            <option value={10}> 10개씩 보기</option>
            <option value={20}> 20개씩 보기</option>
            <option value={30}> 30개씩 보기</option>
          </select>
          <DownIcon />
        </div>
        <button onClick={() => setCurrentPage(0)} disabled={currentPage === 0}>
          <BigLeft />
        </button>
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 0}
        >
          <SmallLeft />
        </button>
        <div id="indexArea">
          {Array.from({ length: totalPages }, (_, index) => (
            <div
              id="index"
              key={index}
              style={{ fontWeight: `${index === currentPage ? "600" : "400"}` }}
              onClick={() => setCurrentPage(index)}
              className={index === currentPage ? "active" : ""}
            >
              {index + 1}
            </div>
          ))}
        </div>

        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages - 1}
        >
          <SmallRight />
        </button>
        <button
          onClick={() => setCurrentPage(totalPages - 1)}
          disabled={currentPage === totalPages - 1}
        >
          <BigRight />
        </button>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: right;
  padding-top: 38px;

  .selectBox {
    position: relative;
    select {
      width: 120px;
      padding: 10px 9px;
      border-radius: 8px;
      outline: none;

      -webkit-appearance: none; /* 네이티브 외형 감추기 */
      -moz-appearance: none;
      appearance: none;
    }

    svg {
      position: absolute;
      right: 10px;
      top: 12px;
    }
  }

  cursor: pointer;

  button {
    outline: none;
    border: none;
    background-color: transparent;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  #indexArea {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0 10px;
  }

  #index {
    color: #767b82;
    gap: 20px;

    ${({ isAcitve }) =>
      isAcitve &&
      css`
        color: #45484c;
      `}
  }
`;

export default Pagination;
