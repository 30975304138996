import api from "./baseApi";

export async function insertPeopleBody(payload) {
  const result = await api.post(`/contents/images`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    transformRequest: [
      function () {
        return payload;
      },
    ],
  });
  return result?.data;
}

export async function getPeopleBodyList() {
  const result = await api.get(`/contents/images`);
  return result?.data;
}

export async function getPeopleBodyDetail(payload) {
  const result = await api.get(`/contents/detail/` + payload.id);
  return result?.data;
}

export async function deletePeopleBodyDetail(payload) {
  const result = await api.delete(`/contents/list/`, {
    data: payload,
  });
  return result?.data;
}

export async function changeOrder(payload) {
  const result = await api.post(`/contents/order`, {
    items: payload,
  });
  return result?.data;
}

export async function updateItem(payload) {
  const result = await api.put(`/contents/images`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    transformRequest: [
      function () {
        return payload;
      },
    ],
  });
  return result?.data;

}
