import Card from "../../components/Card";
import styled, { css } from "styled-components";
import { useNavigate } from "react-router";
import { useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { getPeopleBodyList } from "../../api/api";

const ContentsPage = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState("");
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1279px)",
  });

  const [list, setList] = useState([]);

  useEffect(() => {
    getPeopleBodyList().then((res) => setList(res.data));
  }, []);

  const getFilteredList = useCallback((type) => {
    setIsActive(type);
  }, []);

  return (
    <Container>
      <BtnGroup>
        <CategoryBtn isActive={isActive === ""} onClick={() => setIsActive("")}>
          All
        </CategoryBtn>
        <CategoryBtn
          isActive={isActive === "people"}
          onClick={() => getFilteredList("people")}
        >
          People
        </CategoryBtn>
        <CategoryBtn
          isActive={isActive === "body"}
          onClick={() => getFilteredList("body")}
        >
          Body
        </CategoryBtn>
      </BtnGroup>
      <Contents id="body" isMobile={isMobile}>
        {list
          .sort((a, b) => a?.order - b?.order)
          .filter((item) => (isActive === "" ? true : item.type === isActive))
          .map((item, index) => {
            return (
              <Card
                src={item.filePath}
                onClick={() => navigate(`/contents/detail/${item.id}`)}
              />
            );
          })}
      </Contents>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BtnGroup = styled.div`
  display: flex;
  padding: 40px;
`;

const CategoryBtn = styled.div`
  height: Fixed (40px) px;
  padding: 9px 20px 9px 20px;
  box-sizing: border-box;
  font-weight: 500;
  gap: 10px;
  opacity: 0px;
  color: white;
  cursor: pointer;

  ${({ isActive }) =>
    isActive &&
    css`
      border: 1px solid white;
      font-weight: 700;
    `}
`;

const Contents = styled.div`
  top: 0;
  height: 100%;
  display: grid;
  gap: 20px;
  // padding-top: 40px;
  grid-template-columns: repeat(3, 1fr);
  min-height: 14rem;
  justify-items: left;
  justify-content: left;
  grid-auto-rows: 1fr;

  align-items: center;
  transition: all 0.8s;
  padding: 40px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      padding: 1.3rem;
    `}
`;

export default ContentsPage;
