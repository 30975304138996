import { memo } from "react";
import styled, { css } from "styled-components";

const Button = ({ text, onClick, type, style, isDisable }) => {
  return (
    <Container
      className="button"
      type={type}
      isDisable={isDisable}
      onClick={isDisable ? null : onClick}
      style={style}
    >
      {text}
    </Container>
  );
};

const Container = styled.div`
  background: #ffffff0f;
  font-size: 18px;
  font-size: 1rem;
  width: auto;
  padding: 10px 16px 10px 16px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-family: "Pretendard";
  border-radius: 8px;
  font-weight: 500;

  ${({ type }) =>
    type === "primary" &&
    css`
      background-color: #303235;
      color: #ffffff;
    `}
  ${({ type }) =>
    type === "secondary" &&
    css`
      background: #ffecec;
      color: #ff6868;
    `}

    ${({ isDisable }) =>
    isDisable &&
    css`
      background: #edf0f4;
      color: #c8cace;
    `}
`;

export default memo(Button);
