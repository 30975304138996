import styled, { css } from "styled-components";
import NameTag from "../../components/NameTag";
import BgText from "../../assest/images/bgText.png";

import Card from "../../components/Card";
import HorizontalScrollGallery from "./HorizontalScrollGallery";
import { useMediaQuery } from "react-responsive";
import { useEffect, useState } from "react";
import { getScrollingList } from "../../api/api";
const AboutPage = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1279px)",
  });
  const [imagesList, setImagesList] = useState([]);

  useEffect(() => {
    getScrollingList().then((res) => {
      setImagesList(res?.data);
    });
  }, []);

  return (
    <Container isMobile={isMobile}>
      <IntroSection isMobile={isMobile}>
        <StyledNameTag />
        <Introduce isMobile={isMobile}>
          <Title>About</Title>
          <SubTitle isMobile={isMobile}>
            Studio AAVEC <br /> 노력만큼 멋진 결과
          </SubTitle>
          <Description>
            Studio AAVEC는 제품의 탁월함을 통해 모든 골퍼에게 승리를 안겨주고자
            탄생된 브랜드입니다. 골퍼들의 감춰져 있던 개개인의 다양한 특성을
            가감 없이 발산할 수 있도록 유니크하면서도 감각적인 제품을
            제안합니다. 필드에서 돋보이고 싶은 골퍼들을 위해 특별하고 즐거운
            라운딩 경험을 선사하고자 합니다.
          </Description>
        </Introduce>
      </IntroSection>

      <SlideBar>
        <HorizontalScrollGallery imagesList={imagesList} />
        <GradiantLeft></GradiantLeft>
        <GradiantRight></GradiantRight>
      </SlideBar>

      <BackgroundText>
        <img src={BgText} alt="bgText" />
      </BackgroundText>
    </Container>
  );
};

const GradiantLeft = styled.div`
  position: absolute;
  left: 0;
  height: 100%;
  box-shadow: 20px 3px 50px 50px #000000bd;
`;

const GradiantRight = styled.div`
  height: 100%;
  position: absolute;
  right: 0;
  box-shadow: -10px 1px 50px 50px #000000bd;
`;

const Gallery = styled.div``;

const SlideBar = styled.div`
  display: flex;
  bottom: 0;
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: cover;
  }
`;

const BackgroundText = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: -999;
  bottom: 126px;
  img {
    width: 100%;
  }
`;

const StyledNameTag = styled(NameTag)`
  flex: 1;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 90px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      gap: 45px;
    `}
`;
const IntroSection = styled.div`
  display: flex;
  align-items: center;
  padding-top: 160px;
  /* width: 100%; */
  gap: 120px;
  flex: 1 1;
  justify-content: center;

  padding-left: 1.5rem;
  padding-right: 1.5rem;

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding-top: 46px;
      flex-direction: column;
    `}
`;

const Introduce = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  max-width: 600px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 1.5rem;
      padding-top: 46px;
      flex-direction: column;
    `}
`;
const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
`;
const SubTitle = styled.div`
  font-size: 44px;
  line-height: 61px;
  font-weight: 600;
  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 30px;
    line-height: 42px;
    font-weight: 600;
    `}
 
`;
const Description = styled.div`
  font-size: 1rem;
  padding-top: 30px;
  line-height: 25.6px;
  font-weight: 400;
`;

export default AboutPage;
