import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import Card from "../../components/Card";
import Button from "../../components/Button";
import { Navigate, useNavigate } from "react-router";
import MoveableSlider from "./MoveableSlider";
import { useMediaQuery } from "react-responsive";
import { getMainContentsList } from "../../api/mainApi";
import { getPeopleBodyList } from "../../api/contentsApi";
import ImageGrid from "./ImageGrid";
import { ReactComponent as RightIcon } from "../../assest/svg/RightIcon.svg";

const MainPage = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const [peopleList, setPeopleList] = useState([]);
  const [bodyList, setBodyList] = useState([]);

  const [imagesList, setImagesList] = useState([]);

  // useEffect(() => {
  //   let observer = new IntersectionObserver(
  //     (entries, observer) => {
  //       entries.forEach((box) => {
  //         if (box.isIntersecting) {
  //           box.target.style.opacity = 1;
  //         } else {
  //           if (!isMobile) {
  //             box.target.style.opacity = 0.4;
  //           }
  //         }
  //       });
  //     },
  //     { threshold: 0.4 }
  //   );
  //   let div = document.getElementById("people");
  //   let body = document.getElementById("body");

  //   observer.observe(div);
  //   observer.observe(body);
  // }, []);

  useEffect(() => {
    getMainContentsList().then((res) => {
      setImagesList(res.data);
    });
    getPeopleBodyList().then((res) => {
      const peopleList = res.data.filter((item) => item.type === "people");
      const bodyList = res.data.filter((item) => item.type === "body");

      setPeopleList(peopleList);
      setBodyList(bodyList);
    });
  }, []);

  const videoRef = useRef(null);

  return (
    <Container>
      <MoveableSlider imagesList={imagesList} isMobile={isMobile} />
      <ImageList isMobile={isMobile}>
        <Tab isMobile={isMobile}>
          People
          <StyledButton
            text={
              <>
                전체 보기
                <RightIcon />
              </>
            }
            onClick={() => navigate("/contents")}
          />
        </Tab>
        <HR />
        <Contents isMobile={isMobile} id="people">
          <ImageGrid imageList={peopleList} isMobile={isMobile} />
        </Contents>

        <div
          style={{
            width: "100%",
            paddingTop: "1.2rem",
            paddingBottom: "1.2rem",
          }}
        ></div>

        <Tab>
          Body
          <StyledButton
            text={
              <>
                전체 보기 <RightIcon />{" "}
              </>
            }
            onClick={() => navigate("/contents")}
          />{" "}
        </Tab>
        <HR />
        <Contents id="body" isMobile={isMobile}>
          <ImageGrid imageList={bodyList} isMobile={isMobile} />
        </Contents>
      </ImageList>
    </Container>
  );
};

const StyledButton = styled(Button)``;

const ImageList = styled.div`
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding-top: 1.3rem;
      padding-left: 1.3rem;
      padding-right: 1.3rem;
    `}
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const HR = styled.div`
  width: 100%;
  height: 2px;
  background-color: white;
`;

const Tab = styled.div`
  width: 100%;
  height: 4rem;
  font-size: 3.75em;
  padding-bottom: 40px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-family: "PretendardBold";
  font-weight: 900;
  color: white;

  ${({ isMobile }) =>
    isMobile &&
    css`
      font-size: 2.75em;
    `}

  .button {
    display: flex;
    padding: 10px 16px !important;
    border-radius: 0px;
    height: 44px;
    font-weight: 600;
    svg {
      padding-left: 4px;
    }
  }
`;

const Contents = styled.div`
  top: 0;
  width: 100%;
  height: 100%;
  /* display: grid; */
  gap: 20px;
  padding-top: 40px;
  /* grid-template-columns: repeat(3, 1fr); */
  /* opacity: 1;

  ${({ isMobile }) =>
    isMobile &&
    css`
      opacity: 1;
    `} */

  display: flex;
  align-items: center;
  justify-content: center;

  align-items: center;
  transition: all 0.8s;
`;

export default MainPage;
