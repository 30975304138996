import api from "./baseApi";

export async function insertMainContents(payload) {
  const result = await api.post(`/main/images`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    transformRequest: [
      function () {
        return payload;
      },
    ],
  });
  return result.data;
}

export async function getMainContentsList() {
  const result = await api.get(`/main/images`);
  return result?.data;
}

export async function getMainDetail(payload) {
  const result = await api.get(`/main/detail/` + payload.id);
  return result?.data;
}

export async function deleteMainItems(payload) {
  const result = await api.delete(`/main/list/`, {
    data: payload,
  });
  return result?.data;
}

export async function changeOrder(payload) {
  const result = await api.post(`/main/order`, {
    items: payload,
  });
  return result?.data;
}

export async function updateItem(payload) {
  const result = await api.post(`/main/update`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    transformRequest: [
      function () {
        return payload;
      },
    ],
  });
  return result?.data;
}
