import { Outlet } from "react-router";
import Footer from "./Footer";
import Header from "./Header";
import styled from "styled-components";
const Main = () => {
  return (
    <MainContainer>
      <Header />
      <Outlet />
      <Footer />
    </MainContainer>
  );
};

const MainContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default Main;


/**
 *  2024.07.21
 *  Developed by. sjlee
 *  Designed by. halim
 */